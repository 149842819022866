
import { defineComponent, onMounted, onBeforeUnmount, onUnmounted, reactive } from "vue";
import mAxiosApi from "@/api";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import * as moment from "moment";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
    myList: {default : [] as any },
  },

  emits: ["affTicket"],
  setup(props, { emit }) {
    let mymap;
    moment.default.locale("fr");

    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
    });

    const zmyList = props.myList as any;

    const clickTicket = (data) => {
      const allList = zmyList.filter(el => el.tic_seq == data.detail.siteId)[0];
      emit("affTicket", allList);
    }

    onMounted(async () => {
      // MAP   59.355596110016315 '  ' 64.77463308614524 '  ' 5          // 46.05, 11.05
      
      const _lat = localStorage.getItem('_leaflet_lat') ? localStorage.getItem('_leaflet_lat') : 46.05;
      const _lng = localStorage.getItem('_leaflet_lng') ? localStorage.getItem('_leaflet_lng') : 11.05;
      const _zoom = localStorage.getItem('_leaflet_zoom') ? localStorage.getItem('_leaflet_zoom') : 5;
      
      const map = L.map("mapContainer").setView([_lat, _lng], _zoom);
      L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png", {
        attribution: "&copy;Stillnetwork",
      }).addTo(map);
      mymap = map;
      var customPane = map.createPane("customPane");

      customPane.style.zIndex = 399;

      zmyList.forEach(
        function(item: any){
          //console.log(item);
          _addMarkerMap(item,map,item.color);

        }
      );

      map.on('zoomend', function() {
        localStorage.setItem('_leaflet_lat', map.getCenter().lat);
        localStorage.setItem('_leaflet_lng', map.getCenter().lng);
        localStorage.setItem('_leaflet_zoom', map._zoom);
      });

      map.on('dragend', function() {
        localStorage.setItem('_leaflet_lat', map.getCenter().lat);
        localStorage.setItem('_leaflet_lng', map.getCenter().lng);
        localStorage.setItem('_leaflet_zoom', map._zoom);
      });

      window.removeEventListener('clickonticket', clickTicket, true);
      window.addEventListener('clickonticket', clickTicket,true);
      
      state.loaderEnabled = false;
    });

    onBeforeUnmount(() => {
      window.removeEventListener('clickonticket', clickTicket, true);
      mymap.remove();
    });
   
    onUnmounted(() => {
      //console.table(listAllEventListeners());
    });


    function _addMarkerMap(obj, map, color = "33b1f9") {

      var iconColor = new L.Icon({
        iconUrl:   "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=•|"+ color +"&chf=a,s,ee00ffff",
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [21, 34], iconAnchor: [10, 34],  popupAnchor: [1, -34], shadowSize: [34,34],  });

      const buttonMap = `<div class="mb-2"><h3>${obj.adr_c_site_label === null ? '' : obj.adr_c_site_label + '<hr>'}</h3></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_1}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_ligne_2}</h5></div>
                         <div class="mb-2"><h5>${obj.adr_cp} - ${obj.adr_ville}</h5></div>

                         <div class="mt-10 text-dark p-3" style="box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 18%);">
                          ${ "<b>Ticket #"+obj.tic_seq +" </b><br>"+ (obj.presta_nom_prenom ? obj.presta_nom_prenom : '') + "<br><span class='text-primary'>"+obj.tic_c_label +"</span>" }
                         </div>

                         <br>
                         <a class="btn btn-sm btn-active-secondary me-2 mt-5 btn-bg-primary btn-text-white" onclick="window.dispatchEvent(new CustomEvent('clickonticket', {detail: {siteId: ${obj.tic_seq}}}));">
                           Afficher le ticket
                         </a>`;

      L.marker([obj.adr_lat, obj.adr_lng], { icon: iconColor })
        .addTo(map)
        .bindPopup(buttonMap);
    }

    return {
      state,
      clickTicket,
      zmyList,
      mymap,
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
